<template>
  <!-- ======= Why Us Section ======= -->
  <section id="why-us" class="why-us">
    <div class="container">
      <div class="row no-gutters ms-3 me-3 ms-md-0 me-md-0">
        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(1) }}</span>
          <h4>Proven Expertise</h4>
          <p>
            As a Ph.D. and Member of the Real Estate Board of Greater Vancouver
            since 2005, I bring a wealth of knowledge and experience to ensure
            your real estate transactions are handled with precision and
            professionalism.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(2) }}</span>
          <h4>Comprehensive Market Insight</h4>
          <p>
            Benefit from my extensive experience and market insights. Receive a
            FREE Home Evaluation and Market Analysis, providing you with a clear
            understanding of your property's market value.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(3) }}</span>
          <h4>Exclusive Access to Properties</h4>
          <p>
            Stay ahead of the competition with my New Listings Notification. I
            have the resources to find homes not yet on the market, giving you a
            head start in securing the hottest properties in Vancouver and
            Fraser Valley.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(4) }}</span>
          <h4>Financial Expertise</h4>
          <p>
            Navigate your financial options seamlessly with the support of
            qualified lenders. I understand the intricacies of real estate
            financing and can guide you to make informed decisions.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(5) }}</span>
          <h4>Strategic Negotiations</h4>
          <p>
            Count on my proven negotiation skills to secure the best deals on
            your behalf. I work diligently to accelerate the closing of property
            sales, ensuring a smooth and efficient process.
          </p>
        </div>

        <div class="col-lg-4 col-md-6 content-item">
          <span>{{ intToRoman(6) }}</span>
          <h4>Unparalleled Advertising Exposure</h4>
          <p>
            Your property will receive 24/7 exposure to millions. I go beyond
            the standard level of service to ensure your home is effectively
            advertised until it's sold.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- End Why Us Section -->
</template>

<script>
export default {
  methods: {
    intToRoman(num) {
      if (num < 1 || num > 3999) {
        return "Invalid number";
      }

      const romanNums = [
        "M",
        "CM",
        "D",
        "CD",
        "C",
        "XC",
        "L",
        "XL",
        "X",
        "IX",
        "V",
        "IV",
        "I",
      ];
      const integerNums = [
        1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1,
      ];

      let romanStr = "";

      for (let i = 0; i < integerNums.length; i++) {
        while (num >= integerNums[i]) {
          romanStr += romanNums[i];
          num -= integerNums[i];
        }
      }

      return romanStr;
    },
  },
};
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.why-us {
  padding: 0;
}

.why-us .row {
  overflow: hidden;
}

.why-us .content-item {
  padding: 40px;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: -1px;
}

.why-us .content-item span {
  display: block;
  font-size: 24px;
  font-weight: 400;
  color: $mainLightBlue;
}

.why-us .content-item h4 {
  font-size: 26px;
  font-weight: 300;
  padding: 0;
  margin: 20px 0;
}

.why-us .content-item p {
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .why-us .content-item {
    padding: 40px 0;
  }
}
</style>