export const CORPORATE_DATA = {
    facebookLink: `#`,
    instagrammLink: `#`,
    phoneNumbers: {
        eugene: `+17788894879`
    },
    address: ``,
    email: `okdavid@ymail.com`,
    supportEmailData: {
        supportEmailSubject: `Royal Pacific Realty Corporation info`,
        supportEmailBody: `Hi Royal Pacific Realty Corporation team,`
    },
    companyTitle: `Royal Pacific Realty Corporation`,
    copyrightTitle: `Royal Pacific Realty Corporation`,
    locationPin: ``,
    locationPinShort: ``,
    formSendUrl: `https://codebridge-email-sender.herokuapp.com/email-sender`,
    imgBaseUrl: `https://objectstorage.ca-toronto-1.oraclecloud.com/p/jE02KCLipZSKqdPxfle5ABFxoo3kkhQaCZySIYDoeGA5vq21F6brBA1inVn0dds4/n/yzfveo9zjrvn/b/bucket-20230109-1321/o/codebridge/`
}