<template>
  <!-- ======= About Us Section ======= -->
  <section id="about" class="about">
    <div class="container">
      <div class="section-title">
        <h2>About</h2>
        <p>My experience and academic education are guarantees of success</p>
      </div>

      <div class="row">
        <div class="col-lg-6 order-1 order-lg-2">
          <img
            src="../../assets/img/about-us-3.jpg"
            class="w-100"
            alt="image"
          />
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
          <h3>Welcome</h3>
          <p class="mt-3">
            I'm David Okropiridze, Ph.D. Member of the Real Estate Board of
            Greater Vancouver since 2005. I'm a licensed professional with many
            years of experience and a proven track record. I know how to make
            your house-selling and buying process enjoyable. If you own real
            estate that you're thinking of selling, I'd be happy to provide you
            with FREE Home Evaluation and Market Analysis. Please complete my
            online submission form and get your property's market value. It’s
            always a great time to sell or buy. Many good homes are sold before
            they are ever advertised. I have resources to find you homes that
            may not even be on the market. Beat other homebuyers to the hottest
            new homes for sale in Vancouver and Fraser Valley areas with my New
            Listings Notification. I have qualified lenders and understand your
            financial options. I know how to successfully negotiate on your
            behalf and can help accelerate the close of the sale of your
            property. Your home will advertised to millions 24 hours a day until
            sold with a level of service above and beyond that of a standard.
            Whether you are a first-time homebuyer, looking to sell your
            property or an experienced investor, I can provide you with
            comprehensive real estate solutions. I invite you to contact me as
            I'd be pleased to assist you with this important transaction. Always
            Free Services for the Buyers. Your satisfaction is my success. In
            addition, if you have any general questions about buying or selling
            real estate in Greater Vancouver or Fraser Valley, please do not
            hesitate to contact me at
            <a
              :href="`mailto:${corporateData.email}?subject=${corporateData.supportEmailData.supportEmailSubject}&body=${corporateData.supportEmailData.supportEmailBody}`"
              class="link-primary"
              >okdavid@ymail.com</a
            >
            as I'm more than willing to help. Please browse my website for
            listings, reports, and important local real estate information.
          </p>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Us Section -->
</template>

<script>
import { CORPORATE_DATA } from "../../constants";

export default {
  data() {
    return {
      corporateData: CORPORATE_DATA,
    };
  },
};
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 28px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #5cb874;
}

.about .content p:last-child {
  margin-bottom: 0;
}
</style>