<template>
  <!-- ======= Our Clients Section ======= -->
  <section id="clients" class="clients pb-0">
    <div class="container">
      <div class="section-title">
        <h2>Services</h2>
        <p>
          Exclusive Services by David Okropiridze, Ph.D., Your Trusted Real
          Estate Professional
        </p>
      </div>

      <div class="clients-slider swiper">
        <div class="swiper-wrapper align-items-center">
          <div class="swiper-slide">
            <img
              src="../../assets/img/services/service-1.jpeg"
              class="img-thumbnail"
              alt="picture"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="../../assets/img/services/service-2.jpeg"
              class="img-thumbnail"
              alt="picture"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="../../assets/img/services/service-3.jpeg"
              class="img-thumbnail"
              alt="picture"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="../../assets/img/services/service-4.jpeg"
              class="img-thumbnail"
              alt="picture"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="../../assets/img/services/service-5.png"
              class="img-thumbnail"
              alt="picture"
            />
          </div>
          <div class="swiper-slide">
            <img
              src="../../assets/img/services/service-6.jpeg"
              class="img-thumbnail"
              alt="picture"
            />
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>
  <!-- End Our Clients Section -->
</template>

<script>
import Swiper, { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
  mounted() {
    this.getClientsSlider();
  },
  methods: {
    getClientsSlider() {
      new Swiper(".clients-slider", {
        speed: 400,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        slidesPerView: "auto",
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          480: {
            slidesPerView: 3,
            spaceBetween: 60,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 80,
          },
          992: {
            slidesPerView: 6,
            spaceBetween: 120,
          },
        },
        modules: [Navigation, Pagination],
      });
    },
  },
};
</script>

<style lang="scss">
/*--------------------------------------------------------------
# Our Clients
--------------------------------------------------------------*/
.clients .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
}

.clients .swiper-slide img:hover {
  opacity: 1;
}

.clients .swiper-pagination {
  margin-top: 40px;
  position: relative;
}

.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid $mainLightBlue;
}

.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: $mainLightBlue;
}
</style>