<template>
  <!-- ======= Services Section ======= -->
  <section id="services" class="services pt-3">
    <div class="container">
      <div class="row">
        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div
            class="icon-box iconbox-blue item-one border border-black rounded p-3"
          >
            <h4 class="text-black text-center">
              Complimentary Home Evaluation and Market Analysis
            </h4>
            <p class="text-black text-center">
              Receive a FREE assessment of your property's market value,
              leveraging my expertise and years of experience in the Greater
              Vancouver real estate market.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <div
            class="icon-box iconbox-orange item-two border border-black rounded p-3"
          >
            <h4 class="text-black text-center">
              Exclusive New Listings Notification
            </h4>
            <p class="text-black text-center">
              Stay ahead of the competition with early access to the hottest new
              homes for sale in Vancouver and Fraser Valley. Benefit from my
              resources to discover properties not yet on the market.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
          data-aos="zoom-in"
          data-aos-delay="300"
        >
          <div
            class="icon-box iconbox-pink item-three border border-black rounded p-3"
          >
            <h4 class="text-black text-center">
              Financial Guidance with Qualified Lenders
            </h4>
            <p class="text-black text-center">
              Navigate your real estate transactions confidently with access to
              qualified lenders. I provide in-depth understanding of your
              financial options to help you make informed decisions.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div
            class="icon-box iconbox-yellow item-four border border-black rounded p-3"
          >
            <h4 class="text-black text-center">
              Strategic Negotiation Expertise
            </h4>
            <p class="text-black text-center">
              Enjoy a smooth and successful negotiation process. I leverage my
              proven track record and negotiation skills to secure the best
              deals on your behalf.
            </p>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <div
            class="icon-box iconbox-red item-five border border-black rounded p-3"
          >
            <h4 class="text-black text-center">
              Continuous 24/7 Advertising Exposure
            </h4>
            <p class="text-black text-center">
              Your property will be advertised to millions around the clock
              until it's sold. Experience a level of service that goes beyond
              the standard, ensuring maximum exposure for your home.
            </p>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
          data-aos="zoom-in"
          data-aos-delay="300"
        >
          <div
            class="icon-box iconbox-teal item-six border border-black rounded p-3"
          >
            <h4 class="text-black text-center">
              Comprehensive Real Estate Solutions
            </h4>
            <p class="text-black text-center">
              Whether you're a first-time homebuyer, selling your property, or
              an experienced investor, I provide tailored and comprehensive real
              estate solutions. Your satisfaction is my top priority throughout
              every step of the transaction.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Services Section -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
// .services .icon-box {
//   text-align: center;
//   padding: 70px 20px 80px 20px;
//   transition: all ease-in-out 0.3s;
//   box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.1);
// }

.services .icon-box .icon {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  position: relative;
}

.services .icon-box .icon i {
  font-size: 36px;
  transition: 0.5s;
  position: relative;
}

.services .icon-box .icon svg {
  position: absolute;
  top: 0;
  left: 0;
}

.services .icon-box .icon svg path {
  transition: 0.5s;
  fill: #f5f5f5;
}

.services .icon-box h4 {
  font-weight: 600;
  margin: 10px 0 15px 0;
  font-size: 22px;
}

.services .icon-box h4 a {
  color: $mainWhite;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  color: $mainWhite;
  margin-bottom: 0;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.08);
}

.services .iconbox-blue i {
  color: #47aeff;
}

.services .iconbox-blue:hover .icon i {
  color: #fff;
}

.services .iconbox-blue:hover .icon path {
  fill: #47aeff;
}

.services .iconbox-orange i {
  color: #ffa76e;
}

.services .iconbox-orange:hover .icon i {
  color: #fff;
}

.services .iconbox-orange:hover .icon path {
  fill: #ffa76e;
}

.services .iconbox-pink i {
  color: #e80368;
}

.services .iconbox-pink:hover .icon i {
  color: #fff;
}

.services .iconbox-pink:hover .icon path {
  fill: #e80368;
}

.services .iconbox-yellow i {
  color: #ffbb2c;
}

.services .iconbox-yellow:hover .icon i {
  color: #fff;
}

.services .iconbox-yellow:hover .icon path {
  fill: #ffbb2c;
}

.services .iconbox-red i {
  color: #ff5828;
}

.services .iconbox-red:hover .icon i {
  color: #fff;
}

.services .iconbox-red:hover .icon path {
  fill: #ff5828;
}

.services .iconbox-teal i {
  color: #11dbcf;
}

.services .iconbox-teal:hover .icon i {
  color: #fff;
}

.services .iconbox-teal:hover .icon path {
  fill: #11dbcf;
}
</style>