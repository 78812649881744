<template>
  <!-- ======= Featured Services Section ======= -->
  <section id="featured-services" class="featured-services section-bg">
    <div class="container">
      <div class="row no-gutters">
        <div class="col-lg-4 col-md-6">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-stack"></i></div>
            <h3 class="title">Quality</h3>
            <p class="description">
              Enjoy a seamless home selling/buying process with my proven
              expertise. Stay ahead in Vancouver and Fraser
              Valley areas with my exclusive New Listings Notification. I work
              with qualified lenders and ensure 24/7 advertising until your
              property is sold. Whether you're a first-time buyer, seller, or
              investor, I offer comprehensive solutions. Contact me for a
              satisfying transaction—your success is my priority. 
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-hand-thumbs-up-fill"></i></div>
            <h4 class="title">Professional</h4>
            <p class="description">
              With a proven track record, I specialize in making your home
              process enjoyable. I work with qualified lenders,
              ensuring seamless transactions. Whether you're a buyer, seller, or
              investor, I offer comprehensive real estate solutions. Contact me
              for a satisfying transaction—your success is my priority. Free
              services for buyers; your satisfaction is my success.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="icon-box">
            <div class="icon"><i class="bi bi-briefcase-fill"></i></div>
            <h4 class="title">Expertise and Experience</h4>
            <p class="description">
              Years of proven success. For a seamless home process, get a
              Home Evaluation and Market Analysis by completing the online form.
              Stay ahead with my New Listings Notification. Qualified lenders,
              24/7 property advertising. Whether buying or selling, I offer
              comprehensive solutions. Contact me for a satisfying
              transaction—your success is my priority. Free services for buyers;
              your satisfaction is my success.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Featured Services Section -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# Featured Services
--------------------------------------------------------------*/
.featured-services {
  padding: 20px 0;
}

.featured-services .icon-box {
  padding: 20px;
}

.featured-services .icon-box-bg {
  background-image: linear-gradient(
    0deg,
    #222222 0%,
    #2f2f2f 50%,
    #222222 100%
  );
}

.featured-services .icon {
  margin-bottom: 15px;
}

.featured-services .icon i {
  color: $mainLightBlue;
  font-size: 42px;
}

.featured-services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.featured-services .title a {
  color: #222222;
  transition: 0.3s;
}

.featured-services .icon-box:hover .title a {
  color: $mainLightBlue;
}

.featured-services .description {
  line-height: 24px;
  font-size: 14px;
}
</style>