<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container">
      <div class="section-title">
        <h2>
          Ready to embark on a seamless real estate journey? I'm here to assist
          you every step of the way. Feel free to reach out for personalized
          guidance and expert advice.
        </h2>
        <p>
          Contact David Okropiridze, Ph.D. - Your Trusted Real Estate
          Professional
        </p>
      </div>

      <div class="row">
        <div class="col-lg-5 d-flex align-items-stretch">
          <div class="info p-5">
            <div class="email">
              <a
                :href="`mailto:${corpData.email}?subject=${corpData.supportEmailData.supportEmailSubject}&body=${corpData.supportEmailData.supportEmailBody}`"
              >
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>{{ corpData.email }}</p>
              </a>
            </div>

            <div class="phone">
              <a :href="`tel:${corpData.phoneNumbers.eugene}`">
                <i class="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>{{ corpData.phoneNumbers.eugene }}</p>
              </a>
            </div>

            <div class="text">
              <a :href="`sms:${corpData.phoneNumbers.eugene}`">
                <i class="bi bi-chat-dots"></i>
                <h4>Text:</h4>
                <p>{{ corpData.phoneNumbers.eugene }}</p>
              </a>
            </div>
          </div>
        </div>

        <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
          <div class="info">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d162.9260962099509!2d-123.13327233510255!3d49.204020793385965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486747745e264fd%3A0x2c734d1f6e2fa68d!2sRoyal%20Pacific%20Realty!5e0!3m2!1sen!2sca!4v1704382283146!5m2!1sen!2sca"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen="true"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Contact Section -->
</template>

<script>
import { CORPORATE_DATA } from "../../constants";
export default {
  data() {
    return {
      corpData: CORPORATE_DATA,
      customerName: ``,
      customerEmail: ``,
      customerSubject: ``,
      customerMsg: ``,
      isMsgLoading: false,
      isMsgSent: false,
      isMsgError: false,
      errorMsg: `Something went wrong please try again.`,
    };
  },
  methods: {
    showRequestError() {
      this.isMsgError = true;
      setTimeout(() => {
        this.isMsgError = false;
      }, 1000);
    },
    showMsgSent() {
      this.isMsgSent = true;
      setTimeout(() => {
        this.isMsgSent = false;
      }, 1000);
    },
    sendRequest() {
      this.isMsgLoading = true;
      setTimeout(() => {
        this.$store
          .dispatch(`SEND_FORM_REQUEST`, {
            email: this.customerEmail,
            name: this.customerName,
            message: this.customerMsg,
          })
          .then((result) => {
            console.log(result);
            this.customerEmail = ``;
            this.customerName = ``;
            this.customerSubject = ``;
            this.customerMsg = ``;
            this.isMsgLoading = false;
            this.showMsgSent();
          })
          .catch(() => {
            this.showRequestError();
          });
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  border-top: 3px solid $mainLightBlue;
  border-bottom: 3px solid $mainLightBlue;
  padding: 30px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .info i {
  font-size: 20px;
  color: $mainPink;
  float: left;
  width: 44px;
  height: 44px;
  background: $mainLightBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #222222;
}

.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #555555;
}

.contact .info .social-links {
  padding-left: 60px;
}

.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}

.contact .info .social-links a:hover {
  background: $mainLightBlue;
  color: #fff;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i,
.contact .info .text:hover i {
  // background: $mainLightBlue;
  background: $mainLightBlueHover;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  border-top: 3px solid $mainLightBlue;
  border-bottom: 3px solid $mainLightBlue;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  // display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  // display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  // display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: $mainLightBlue;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: $mainLightBlue;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: $mainLightBlueHover;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
iframe {
  width: 100%;
  height: 100%;
}
</style>